.cbt-blue {
    color: $cbt-blue;
}

.sole-splash-header {

    padding-top: 100px;

    .bg {
        width: 100%;
        height: 23em;
        background-image: url('/img/solecobat/bg-panels.jpg');
        background-position: center;
        background-size: cover;
    }

}

.box-row {
    margin-top: 64px;

    .box-item {
        position: relative;

        .box-text {
            position: absolute;
            top: -64px;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            padding: 20px;
            max-width: 66%;
            min-height: 15em;
        }

        .bg-installatori {
            background-color: #fdbf04;
            color: white;
        }

        .bg-pa {
            background-color: #3969e3;
            color: white;
        }

        .bg-gse {
            background-color: #1cc98e;
            color: white;
        }
    }

}

.ftv.ftv-search {
    .handsontable {
        th, td {
            font-size: 1.2rem;
            line-height: 1.9rem;
            vertical-align: middle;
        }

        td {
            min-width: 10rem;
        }

        .ht-serial-attivo {
            background-color: rgb(179, 218, 179);
        }

        .ht-serial-non-installato {
            background-color: rgb(196, 196, 196);
        }

        .ht-serial-non-trovato {
            background-color: rgb(206, 161, 161);
        }
    }

}
